<template>
    <div class="stree-div">
        <div class="top-title">
            <div class="left">
            <div class="title-container">
                <img src="https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20220325/503513808.png" alt="">
            </div>
            <div class="left-box-shadow">
                <div class="item-div" v-if="boxList && boxList.length">
                    <div :class="item.class" v-for="(item,index) in boxList" :key="index">
                        <div class="top-div">
                            <div class="div-left">
                                <div class="title">{{item.title}}</div>
                                <div class="num">{{item.num}} <span class="span">{{item.pre}}</span></div>
                            </div>
                        </div>
                    </div>        
                </div>
                <div class="item-div" v-else>
                        <div class="item-none" style="line-height:170px;color:#ccc">
                            暂无数据
                        </div>
                    </div> 
            </div>
            
            </div>
            <div class="right">
                <div class="title-container">
                    <img src="https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20220325/881308791.png" alt="">
                </div>
                <div class="right-box-shadow">
                    <div class="item-div">
                            <div class="item margin-item right-item background1">
                                <div class="top-div">
                                    <div class="div-left div-left-other">
                                        <div class="title">已检:</div>
                                        <div class="num-div">
                                            <div class="num">{{reCheckHu || 0}} <span class="span">户</span></div>
                                            <div class="num">{{reCheck || 0}} <span class="span">人</span></div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>  
                            <div class="item right-item background2">
                                <div class="top-div">
                                    <div class="div-left div-left-other">
                                        <div class="title">未检:</div>
                                        <div class="num-div">
                                            <div class="num red">{{noCheckHu || 0}} <span class="span">户</span></div>
                                            <div class="num red">{{noCheck ||  0}} <span class="span">人</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                    </div> 
                </div>
            </div>
        </div>
        <div class="table-title">
            <div class="tip-div">
                <div class="desc">
                    图示说明：
                </div>
                <div class="room-desc">
                    房号/门牌号:
                </div>
                <div class="first">
                    <div class="white-div"></div>
                    <div class="white-num">
                        <div class="white-count">{{noneBuild}}</div>
                        <div class="white-title">未入驻居民</div>
                    </div>

                    <div class="yellow-div"></div>
                    <div class="yellow-num">
                        <div class="yellow-count">{{noCheck}}</div>
                        <div class="yellow-title">未检</div>
                    </div>
                    
                    <div class="blue-div"></div>
                    <div class="blue-num">
                        <div class="blue-count">{{reCheck}}</div>
                        <div class="blue-title">已检</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-div">
            <div class="item-div" v-for="(item,index) in category2" :key="index">
                <template v-if="(category2 && category2.length)">
                     <div class="name-div">
                        <span style="font-weight:bold;margin-right:20px;font-size:20px">{{item.name}}</span> 
                        <span style="margin-right:20px">楼栋/楼组长:{{item.manages ? item.manages.join(',') : ''}}</span>
                        <span style="margin-right:20px">已检： 
                            <span style="color:green;font-size:18px">{{item.appointmentHouseNum || 0}}</span> 户
                            <span style="color:green;font-size:18px">{{item.appointmentUser || 0}}</span> 人
                            </span>
                        <span style="margin-right:20px">未检： 
                            <span style="color:red;font-size:18px">{{(item.houseNum - item.appointmentHouseNum) > 0 ? (item.houseNum - item.appointmentHouseNum) : 0}}</span> 户
                            <span style="color:red;font-size:18px">{{(item.onlineUser - item.appointmentUser) > 0 ? item.onlineUser - item.appointmentUser : 0}}</span> 人</span>
                    </div>
                    <div class="box-div">
                        <div :class="(items.onlineUser - items.appointmentUser > 0 ) ? 'box-item yellow' : (items.appointmentUser > 0 && items.onlineUser - items.appointmentUser == 0 ? 'box-item blue' : (items.appointmentUser == 0 && items.onlineUser - items.appointmentUser == 0 ? 'box-item':'box-item')) " v-for="(items,indexs) in item.childsss" :key="indexs">
                            <div class="bottom-item-title">{{items.name}}</div>
                            <!-- <div>已检：{{items.appointmentUser}}</div>
                            <div>未检：{{(items.onlineUser - items.appointmentUser >= 0 ) ? items.onlineUser - items.appointmentUser : 0}}</div> -->
                            <div class="bottom-item-last">
                                <div>
                                    已检 <span style="color:67a80f">{{items.appointmentUser || 0}}</span>  人
                                </div>
                                <div>
                                    未检 <span style="color:red">{{(items.onlineUser - items.appointmentUser >= 0 ) ? items.onlineUser - items.appointmentUser : 0}}</span> 人
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
               
            </div>
            <div class="item-div" v-for="(item,index) in category3" :key="index">
                <template v-if="(category3 && category3.length)">
                     <div class="name-div">
                        <span style="font-weight:bold;margin-right:20px;font-size:20px">{{item.name}}</span> 
                        <span style="margin-right:20px">楼栋/楼组长:{{item.manages ? item.manages.join(',') : ''}}</span>
                        <span style="margin-right:20px">已检： 
                            <span style="color:green;font-size:18px">{{item.appointmentHouseNum || 0}}</span> 户
                            <span style="color:green;font-size:18px">{{item.appointmentUser || 0}}</span> 人
                            </span>
                        <span style="margin-right:20px">未检： 
                            <span style="color:red;font-size:18px">{{(item.houseNum - item.appointmentHouseNum) > 0 ? (item.houseNum - item.appointmentHouseNum) : 0}}</span> 户
                            <span style="color:red;font-size:18px">{{(item.onlineUser - item.appointmentUser) > 0 ? item.onlineUser - item.appointmentUser : 0}}</span> 人</span>
                    </div>
                    <div class="box-div">
                        <div :class="(items.onlineUser - items.appointmentUser > 0 ) ? 'box-item yellow' : (items.appointmentUser > 0 && items.onlineUser - items.appointmentUser == 0 ? 'box-item blue' : (items.appointmentUser == 0 && items.onlineUser - items.appointmentUser == 0 ? 'box-item':'box-item')) " v-for="(items,indexs) in item.childs" :key="indexs">
                            <div class="bottom-item-title">{{items.name}}</div>
                            <!-- <div>已检：{{items.appointmentUser}}</div>
                            <div>未检：{{(items.onlineUser - items.appointmentUser >= 0 ) ? items.onlineUser - items.appointmentUser : 0}}</div> -->
                            <div class="bottom-item-last">
                                <div>
                                    已检<span style="color:67a80f">{{items.appointmentUser || 0}}</span>  人
                                </div>
                                <div>
                                    未检<span style="color:red">{{(items.onlineUser - items.appointmentUser >= 0 ) ? items.onlineUser - items.appointmentUser : 0}}</span> 人
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
               
            </div>
            <div class="item-div-last" >
                <div class="box-div">
                    <div :class="(items.onlineUser - items.appointmentUser > 0 ) ? 'box-item yellow' : (items.appointmentUser > 0 && items.onlineUser - items.appointmentUser == 0 ? 'box-item blue' : (items.appointmentUser == 0 && items.onlineUser - items.appointmentUser == 0 ? 'box-item':'box-item'))" v-for="(items,index) in category5" :key="index">
                        <div class="bottom-item-title">{{items.name}}</div>
                        <div class="bottom-item-last">
                            <div>
                                已检: <span style="color:67a80f">{{items.appointmentUser || 0}}</span>  人
                            </div>
                            <div>
                                未检: <span style="color:red">{{(items.onlineUser - items.appointmentUser >= 0 ) ? items.onlineUser - items.appointmentUser : 0}}</span> 人
                            </div>
                        </div>
                        <!-- <div>已检：{{items.appointmentUser}}</div>
                        <div>未检：{{(items.onlineUser - items.appointmentUser >= 0 ) ? items.onlineUser - items.appointmentUser : 0}}</div> -->
                    </div>
                </div>
            </div>  
        </div>
    </div>
</template>

<script>
export default {
    props:{
        tableData:{},
        tableList:[],

        
    },
    data(){
       return{
           boxList:[],
           category2:[],
           category3:[],
           category4:[],
           category5:[],

            // 已检人
           reCheck:0,
            // 已检户    
           reCheckHu:0,
            // 未检人    
           noCheck:0,
            // 未检户
           noCheckHu:0,
            // 未入驻人数
           noneBuild:0
       }
    },
    created(){
    },
    methods:{
        formattData(datas){
            let data = datas
            
            if(data && data.length){
                data.map(item =>{
                    let otherList = []
                    if(item.childs && item.childs.length){
                        item.childs.map(items =>{     
                            if(items.category == '3' && items.childs && items.childs.length){
                                items.childs.map(itemss =>{
                                    otherList.push(itemss)
                                })
                            }else{
                                otherList.push(items)
                            }
                        })
                    }
                    item.childsss = otherList
                })
            }
            // return {code,otherList}
        },
    },
    watch:{
        tableData:{
            handler(val){
                console.log('val',val);
                if(val){
                    if(val.childs && val.childs.length){
                        this.boxList = [
                            {title:'总楼组/楼栋数',num: 0,class:'item left-item background2',pre:'个'},
                            {title:'总户数',num:val.houseNum || 0,class:'item left-item background3',pre:'户'},
                            {title:'总人数',num:val.onlineUser || 0,class:'item left-item background4',pre:'人'},
                        ]
                        this.reCheck = val.appointmentUser
                        this.reCheckHu =  val.appointmentHouseNum
                        this.noCheck = (val.onlineUser - val.appointmentUser)
                        this.noCheckHu = (val.houseNum - val.appointmentHouseNum)
                        this.noneBuild = val.noHasUserHouse
                    }
                }
            },
            deep: true
        },
        tableList:{
            handler(val){
                this.category2 = []
                this.category3 = []
                this.category4 = []
                this.category5 = []
                if(val && val.childs && val.childs.length){
                    val.childs.map(item =>{
                        if(item.category && item.category != ''){
                            if(item.category == '2'){
                                this.category2.push(item)
                            }
                            if(item.category == '3'){
                                this.category3.push(item)   
                            }else if(item.category == '4'){
                                this.category4.push(item)
                            }else if(item.category == '5'){
                                this.category5.push(item)
                            }
                        }  
                    })

                }
                if(this.category2 && this.category2.length){
                    this.boxList[0].num = this.category2.length 
                    this.formattData(this.category2)
                }else if(this.category3 && this.category3.length){
                    this.boxList[0].num = this.category3.length 
                }
                   
                
            },
            deep:true
        }
    }
}
</script>

<style lang="less" scoped>
.stree-div{
    margin:20px 0;
    .top-title{
            display: flex;
            .left{
                width: 60%;
                // border: 1px solid #ccc;
                height: 170px;
                border-radius: 10px;
                margin-right: 20px;
                background: #ffffff;
                box-shadow: 0 0 8px rgba(0,0,0,0.25) ;
                position: relative;
                cursor: pointer;
                 &:hover{
                     box-shadow: 0px 0px 13px rgba(0,0,0,0.5);
                }
            }
            .left-box-shadow{
                height: 100%;
                box-shadow: 0 0 70px  rgba(249,231,198,0.42) inset;
            }
            .right-box-shadow{
                height: 100%;
                box-shadow: 0 0 70px  rgba(249,231,198,0.42) inset;
            }
            .right{
                background: #ffffff;
                width: 40%;
                border-radius: 10px;
                box-shadow:0 0 8px rgba(0,0,0,0.25);
                position: relative;
                height: 170px;
                cursor: pointer;
                 &:hover{
                     box-shadow: 0px 0px 13px rgba(0,0,0,0.5);
                }
            }  
            .title-container{
                position:absolute;
                top: -22px;
                left: 10px;
            } 
            .left-item{
                width: 20%;
            }
            .right-item{
                width: 50%;
            }
           .item-div{
                display: flex;
                width: 100%;
                justify-content: center;
                padding: 10px;
                height: 100%;
                color: black;
                // .margin-item{
                //     margin: 20px 0px 20px 20px !important;
                // }
                .item{
                    font-size: 16px;
                    padding: 10px 0;
                    color: black;
                    margin: 20px;
                    border-right: 1px solid black;
                    &:nth-last-child(1){
                        border-right: none;
                    }
                        .top-div{
                            display: flex;
                            justify-content: center;
                            .div-left{
                                .title{
                                    opacity: 1;
                                    font-size: 18px;
                                    line-height: 32px;
                                    margin-bottom: 20px;
                                    color: #333333;
                                }
                                .num{
                                    font-size: 40px;
                                    margin-bottom: 5px;
                                    color: #333333;
                                    .span{
                                        font-size: 16px;
                                        line-height: 28px;
                                        color: #666;
                                        opacity: 1;
                                        padding-left: 20px;
                                    }
                                }
                            }
                            .div-left-other{
                                display: flex;
                                .title{
                                    font-weight: bold;
                                    margin-top: 20px;
                                }
                                .num-div{
                                    margin-left: 20px;
                                    .num{
                                        padding: 3px;
                                        color:#00AA58 ;
                                        border-bottom: 1px solid #00AA58;
                                        border-width: 3px;
                                        &:nth-last-child(1){
                                            padding: 5px 3px 3px 3px;
                                            border-bottom: none;
                                        }
                                    }
                                }
                                .red{
                                    padding: 3px !important;
                                    color: #EE4A39 !important;
                                    border-bottom: 1px solid #EE4A39 !important;
                                    border-width: 3px !important;
                                    &:nth-last-child(1){
                                            padding: 5px 3px 3px 3px !important;
                                            border-bottom: none !important;
                                        }
                                }
                                
                                
                            }
                            .div-right{
                                font-size: 20px;
                                margin-right: 20px;
                                .num1{
                                    line-height: 32px;
                                }
                                .num2{
                                    line-height: 32px;
                                    margin-top: 20px;
                                }
                            }
                        }
                        .bottom-div{
                            height: 60px;
                        }
                    }
                    // .background1{
                    //     background-image: linear-gradient(to right,#5770FB, #C577FF)
                    // }
                    // .background2{
                    //     background-image: linear-gradient(to right,#40ACF7,#737CFF)
                    // }
                    // .background3{
                    //     background-image: linear-gradient(to right,#ED6281,#F09D5E)
                    // }
                    // .background4{
                    //     background-image: linear-gradient(to right,#3DB684,#95CF4B)
                    // }
            }
    }
    .table-title{
        width: 100%;
         box-shadow: 0px 0px 13px rgba(0,0,0,0.2);
         cursor: pointer;
        .tip-div{
            width: 100%;
            margin: 20px 0;
            display: flex;
            padding: 20px;
            background: #ffffff;
            border-radius: 10px;
        }
        .desc,.room-desc{
            margin:10px 10px 0 10px;
            
        }
        .first{
            display: flex;     
            .white-div{
                width: 80px;
                height: 40px;
                background: #ffffff;
                border: 1px solid #ccc;
                margin-right: 10px;
            }   
            .yellow-div{
                width: 80px;
                height: 40px;
                // background: #f7d48f;
               box-shadow: 0 0 27px  rgba(255,168,0,0.42) inset;
                border: 1px solid #ccc;
                margin-right: 10px;
                margin-left: 40px;
            }
            .blue-div{
                width: 80px;
                height: 40px;
                // background: #9adfdf;
                box-shadow:0 0 27px  rgba(0,137,172,0.42) inset;
                border: 1px solid #ccc;
                margin-right: 10px;
                margin-left: 40px;
            }
            .white-num{
                margin:0 5px;
                .white-count{
                    text-align: center;
                }
            }
            .yellow-num{
                margin:0 5px;
                .yellow-count{
                    text-align: center;
                }
            }
            .blue-num{
                margin:0 5px;
                .blue-count{
                    text-align: center;
                }
            }
        }
    }
    .container-div{
        margin: 20px 0;
        width: 100%;
        .item-div{
            margin: 20px 0 ;
            width: 100%;
            
            .name-div{
                display: flex;
                padding: 20px;
                background: #fff;
                display: inline-block;
                border-radius: 10px;
            }
            .box-div{
                width: 100%;
                flex-wrap: wrap;
                display: flex;
                background: #ffffff;
                margin: 20px 0;
                .box-item{
                    width: 10%;
                    margin: 10px 15px;
                    padding: 20px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    cursor: pointer;
                    &>div{
                        margin: 5px 0;
                    }
                }
                .yellow{
                    box-shadow: 0 0 70px  rgba(255,168,0,0.42) inset;
                }
                .blue{
                    box-shadow:0 0 70px  rgba(0,137,172,0.42) inset;
                }
            }
        }
        .item-div-last{
            margin: 20px 0 ;
            width: 100%;
            .box-div{
                width: 100%;
                flex-wrap: wrap;
                display: flex;
                background: #ffffff;
                .box-item{
                    width: 10%;
                    margin: 10px 15px;
                    padding: 20px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    cursor: pointer;
                    &>div{
                        margin: 5px 0;
                    }
                }
                .yellow{
                    // background:#f7d48f ;
                    box-shadow: 0 0 70px  rgba(255,168,0,0.42) inset;
                }
                .blue{
                    box-shadow:0 0 70px  rgba(0,137,172,0.42) inset;
                }
            }
        }
    }
}
.bottom-item-title{
                display: inline-block;
                margin-bottom: 5px;
                color: #ffffff;
                background:#2392ae;
                padding: 2px 20px;
                font-size: 20px;
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .bottom-item-last{
                margin-top: 10px;
                // display: flex;
                font-size: 18px;
                &>div{
                    margin-right: 20px;
                }
            }
</style>